
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import moment from 'moment';
import { debounce, isEmpty, isString, isUndefined } from 'lodash';
import { FilePondFile, FilePondInitialFile, FileStatus } from 'filepond';
import { ErrorHelper } from '@/modules/core/core-helpers';
import { CoreInputValidators } from '@/modules/core/core-input-validators';
import { ProcessEnvBaseModel } from '@/modules/core/core-types';
import Offer, { dateTimeFields } from '@/models/Offer';
import {
  ActionTypes as OffersActionTypes,
  GetterTypes as OffersGetterTypes,
  OfferResponseData,
  ScheduleOfferPayload
} from '@/store/offers/types';
import { PwrFile } from '@/components/Pwr/PwrFiles/types';
import { RoutesNames as AdminRoutesNames } from '@/router/admin/types';
import { UploadState } from '@/components/Pwr/PwrFiles/lang/types';
import { Dictionary } from '@/types';
import OffersService from '@/services/offers/OffersService';
import { PwrSnackbarTypes } from '@/components/Pwr/PwrSnackbar/types';
import { RoutesNames as OfferRoutesNames } from '@/router/offers/types';
import FilesHelper from '@/helpers/files/FilesHelper';
import { RichTextField } from '@/views/AdminPanel/AdminOffers/types';
import PwrVue from '@/components/PwrVue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrYesNoDialog from '@/components/Pwr/Dialogs/PwrYesNoDialog.vue';
import OpTextEditor from '@/components/Op/Text/OpTextEditor.vue';
import OpOfferPreview from '@/components/Op/Offers/OpOfferPreview.vue';
import OpScheduleConfirmation from '@/components/Op/Offers/OpScheduleConfirmation.vue';
import PwrFileUploader from '@/components/Pwr/PwrFiles/PwrFileUploader.vue';
import CompetitionConclusionDialog from '@/views/AdminPanel/AdminOffers/Components/CompetitionConclusionDialog.vue';

const offersNamespace = 'offers';

@Component({
  components: {
    CompetitionConclusionDialog,
    PwrFileUploader,
    OpScheduleConfirmation,
    PwrYesNoDialog,
    OpOfferPreview,
    OpTextEditor,
    PwrCardTitle,
    PwrCard,
    PwrBtn
  }
})
export default class AdminEditOfferView extends PwrVue {
  // Offers vuex
  @Action(OffersActionTypes.FETCH_OFFERS, { namespace: offersNamespace }) fetchOffers!: () => void;

  @Getter(OffersGetterTypes.GET_OFFERS, { namespace: offersNamespace })
  offers!: Offer[];

  // Publish
  @Action(OffersActionTypes.PUBLISH_OFFER, { namespace: offersNamespace }) publishOffer!: (
    offer: Offer
  ) => Promise<boolean>;

  // Schedule
  @Action(OffersActionTypes.SCHEDULE_OFFER, { namespace: offersNamespace }) scheduleOffer!: (
    payload: ScheduleOfferPayload
  ) => Promise<boolean>;

  // Archive
  @Action(OffersActionTypes.ARCHIVE_OFFER, { namespace: offersNamespace }) archiveOffer!: (
    offer: Offer
  ) => Promise<boolean>;

  // Clear last action response
  @Action(OffersActionTypes.CLEAR_LAST_ACTION_RESPONSE, { namespace: offersNamespace })
  clearLastActionResponse!: () => Promise<boolean>;

  // Last action
  @Getter(OffersGetterTypes.GET_LAST_ACTION_RESPONSE_CODE, { namespace: offersNamespace })
  lastActionResponseCode!: number | undefined;

  @Getter(OffersGetterTypes.GET_LAST_ACTION_RESPONSE_DATA, { namespace: offersNamespace })
  lastActionResponseData!: string | OfferResponseData | undefined;

  private initialOffer: Offer = {};
  private offer: Offer = {};
  private editedFields = new Set();
  private debounceResetAfkTimer = debounce(this.resetAfkTimer, 5000);

  private async created(): Promise<void> {
    const tmpOffer = await this.recoverOffer();

    if (!tmpOffer) {
      return;
    }

    this.offer = tmpOffer;
    this.initialOffer = JSON.parse(JSON.stringify(tmpOffer)); // Needs to ba a copy!
    this.trimTimeFromDate();
    // this.autoFillEmptyDates();
  }

  /**
   * defaults
   */

  // private onDefaultRodo(): void {
  //   this.offer.rodo = this.defaultRodo;
  //   this.onInputField('rodo');
  // }

  private onDefaultFooter(): void {
    this.offer.footer = this.defaultFooter;
    this.onInputField('footer');
  }

  // private defaultRodo =
  //   '{"time":1642594153947,"blocks":[{"id":"nTbg5oe6u1","type":"paragraph","data":{"text":"<b>Uprzejmie prosimy o zapoznanie się z poniższymi informacjami dotyczącymi przetwarzania Państwa danych osobowych w celu rekrutacji. Jeśli zdecydujecie się Państwo przesłać do nas swoje dokumenty aplikacyjne, bardzo prosimy aby dołączyć do CV poniższą klauzulę zgody RODO.</b>"}},{"id":"RJXRXE3JoP","type":"paragraph","data":{"text":"<i>Wyrażam zgodę na przetwarzanie moich danych osobowych przez Politechnikę Wrocławską z siedzibą we Wrocławiu przy Wybrzeżu Wyspiańskiego 27, 50-370 Wrocław, dla potrzeb niezbędnych do realizacji procesu rekrutacji zgodnie z art. 13 ust. 1 i ust. 2 rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO).</i>"}}],"version":"2.22.2"}';

  private defaultFooter =
    '{"time":1642594027655,"blocks":[{"id":"Z8jP40c0nG","type":"paragraph","data":{"text":"Serdecznie dziękujemy za przesłanie swojego CV. "}},{"id":"qZ7sde7s-J","type":"paragraph","data":{"text":"Uprzejmie informujemy, że kontaktujemy się tylko z wybranymi osobami, które najpełniej spełniają wymogi stanowiska."}},{"id":"cKRBltl-K9","type":"paragraph","data":{"text":"W przypadku wcześniejszego zakończenia rekrutacji, powyższe ogłoszenie przestanie być aktywne."}}],"version":"2.22.2"}';

  /**
   * @private Return only edited key => value
   */
  private editedOffer(): Offer | null {
    if (this.editedFields.size > 0) {
      const editedOffer: Offer = {
        uuid: this.offer.uuid
      };

      const editedKeys = Array.from(this.editedFields) as [];

      if (editedKeys) {
        editedKeys.forEach((key: string) => {
          let value = this.offer[key as keyof Offer];

          if (dateTimeFields.includes(key as keyof Offer)) {
            // @ts-ignore
            value = moment(value).format('YYYY-MM-DD HH:mm:ss');
          }

          // @ts-ignore
          editedOffer[key] = value;
        });
      }

      return editedOffer;
    }

    return null;
  }

  private mounted(): void {
    this.lastActionResponseError();
    this.clearLastActionResponse();
  }

  private async recoverOffer(): Promise<Offer | undefined> {
    const offerUuid: string | undefined = this.$route.params.uuid;
    return this.offersHelper.recoverOffer(offerUuid);
  }

  private trimTimeFromDate() {
    this.offer.createdAt = this.offer.createdAt
      ? this.getDatetimeDate(this.offer.createdAt)
      : undefined;
    this.offer.expirationDate = this.offer.expirationDate
      ? this.getDatetimeDate(this.offer.expirationDate)
      : undefined;
    this.offer.publicationDate = this.offer.publicationDate
      ? this.getDatetimeDate(this.offer.publicationDate)
      : undefined;
    this.offer.termOfEmployment = this.offer.termOfEmployment
      ? this.getDatetimeDate(this.offer.termOfEmployment)
      : undefined;
    this.offer.updatedAt = this.offer.updatedAt
      ? this.getDatetimeDate(this.offer.updatedAt)
      : undefined;
    this.offer.readinessContestSettlementDate = this.offer.readinessContestSettlementDate
      ? this.getDatetimeDate(this.offer.readinessContestSettlementDate)
      : undefined;

    this.documentsSubmissionDeadlineDate = this.offer.documentsSubmissionDeadline
      ? this.getDatetimeDate(this.offer.documentsSubmissionDeadline)
      : undefined;
    this.documentsSubmissionDeadlineTime = this.offer.documentsSubmissionDeadline
      ? this.getDatetimeTimeWithoutSeconds(this.offer.documentsSubmissionDeadline)
      : undefined;
  }

  private setUpOfferDocumentsSubmissionDeadline() {
    if (this.documentsSubmissionDeadlineDate || this.documentsSubmissionDeadlineTime) {
      if (!this.documentsSubmissionDeadlineDate) {
        this.documentsSubmissionDeadlineDate = moment().format('YYYY-MM-DD');
      }

      if (!this.documentsSubmissionDeadlineTime) {
        this.documentsSubmissionDeadlineTime = '00:00';
      }

      this.offer.documentsSubmissionDeadline = `${this.documentsSubmissionDeadlineDate} ${this.documentsSubmissionDeadlineTime}:00`;

      this.onInputField('documentsSubmissionDeadline');
    }
  }

  private lastActionResponseError(error?: unknown): boolean {
    let errorCode = this.lastActionResponseCode;
    let errorData = this.lastActionResponseData;

    if (error) {
      const axiosError = ErrorHelper.getAxiosError(error);

      if (axiosError) {
        errorCode = axiosError.status;
        errorData = axiosError.data.message || axiosError.data;
      } else {
        return true;
      }
    }

    if (errorData && errorCode === 422) {
      if (isString(errorData)) {
        this.showErrorSnackbar(errorData);
      } else if (errorData.errors.publicationDate) {
        this.showErrorSnackbar(errorData.errors.publicationDate[0] as string);
      } else {
        this.errors = errorData.errors || [];
        return true; // Return true to trigger error snackbar
      }
      return false;
    }

    return true;
  }

  get selectedOffer(): Offer | undefined {
    if (this.$route.query?.uuid) {
      return this.offers.filter((offer: Offer) => offer.uuid === this.$route.query?.uuid)[0];
    }

    if (this.$route.params?.selectedUuid && this.$route.params?.selectedUuid.length) {
      return this.offers.filter(
        (offer: Offer) => offer.uuid === this.$route.params.selectedUuid
      )[0];
    }
    return undefined;
  }

  private isState(stateConstant: string): boolean {
    return this.getConstantByPath(`offer.state.${this.offer.state}`) === stateConstant;
  }

  private isType(typeConstant: string | null | undefined = undefined): boolean {
    if (typeConstant === null || typeConstant === undefined) {
      return this.offer.type === undefined;
    }
    return this.getConstantByPath(`offer.type.${this.offer.type}`) === typeConstant;
  }

  /**
   * Form
   */
  private formValid = true;
  private errors: Dictionary<string[]> = {};

  private externalErrors(): boolean {
    const errorFields = Object.keys(this.errors);

    // eslint-disable-next-line consistent-return
    errorFields.forEach((field: string) => {
      if (this.errors[field] && this.errors[field].length > 0) {
        return true;
      }
    });

    return false;
  }

  private menuTermOfEmployment: boolean = false;
  private menuExpirationDate: boolean = false;
  private menuDocumentsSubmissionDeadlineDate: boolean = false;
  private menuDocumentsSubmissionDeadlineTime: boolean = false;
  private menuReadinessContestSettlementDate: boolean = false;

  private documentsSubmissionDeadlineTime: string | undefined = '';
  private documentsSubmissionDeadlineDate: string | undefined = '';

  private publicationConfirmationLoading: boolean = false;
  private publicationConfirmation: boolean = false;

  private scheduleConfirmationLoading: boolean = false;
  private scheduleConfirmation: boolean = false;

  private archiveConfirmation: boolean = false;
  private archiveConfirmationLoading: boolean = false;

  /**
   * Form rules
   */

  private isDraft(): boolean {
    return this.getConstantByPath(`offer.state.${this.offer.state}`) === 'DRAFT';
  }

  private hasErrors(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private isRequiredGuard(input: any): boolean {
    return !this.isDraft() || this.hasErrors() || !isUndefined(input);
  }

  private type(): string {
    return this.getConstantByPath(`offer.type.${this.offer.type}`) ?? '';
  }

  private rules = {
    city: [
      (v: number) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    contractType: [
      (v: number) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    workingTime: [
      (v: number) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    documentsSubmissionDeadline: [
      (v: string) => {
        if (
          (this.type() === 'TEACHER_FROM_CONTEST' ||
            this.type() === 'TEACHER_WITHOUT_CONTEST' ||
            this.type() === 'TEACHER_FROM_PROJECT') &&
          this.isRequiredGuard(v)
        ) {
          return CoreInputValidators.required(v);
        }

        return true;
      },

      (v: string) => {
        if (this.getExpirationDate()) {
          return CoreInputValidators.maxDate(
            moment(v).startOf('day'),
            moment(this.getExpirationDate()).startOf('day'),
            true,
            this.getLabelByPath('offer.attributes.expirationDate')
          );
        }

        return true;
      },

      (v: string) => {
        if (this.getPublicationDate()) {
          return CoreInputValidators.minDate(
            moment(v).startOf('day'),
            moment(this.getPublicationDate()).startOf('day'),
            false,
            this.getLabelByPath('offer.attributes.publicationDate')
          );
        }

        return true;
      }
    ],

    publicationDate: [
      (v: string) =>
        CoreInputValidators.minDate(
          moment(v).startOf('day'),
          moment().startOf('day'),
          true,
          this.$t('date.today') as string
        ),

      (v: string) => {
        if (this.getExpirationDate()) {
          return CoreInputValidators.maxDate(
            moment(v).startOf('day'),
            moment(this.getExpirationDate()).startOf('day'),
            false,
            this.getLabelByPath('offer.attributes.expirationDate')
          );
        }

        return true;
      }
    ],
    termOfEmployment: [
      (v: string) =>
        CoreInputValidators.minDate(
          moment(v).startOf('day'),
          moment().startOf('day'),
          true,
          this.$t('date.today') as string
        )
    ],
    expirationDate: [
      (v: string) => {
        if (!isEmpty(v)) {
          return CoreInputValidators.minDate(
            moment(v).startOf('day'),
            moment(this.dateScopes.expirationDate.min()).subtract(1, 'day').startOf('day'),
            false,
            this.getLabelByPath('offer.attributes.expirationDate') as string
          );
        }
        return true;
      }
    ],
    additionalInfo: [
      (v: string) => {
        if (
          (this.type() === 'TEACHER_FROM_CONTEST' ||
            this.type() === 'TEACHER_WITHOUT_CONTEST' ||
            this.type() === 'TEACHER_FROM_PROJECT') &&
          this.isRequiredGuard(v)
        ) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    abstract: [
      (v: string) => {
        if (v && v.length) {
          return CoreInputValidators.maxRule(
            v,
            255,
            true,
            this.getLabelByPath('offer.attributes.abstract')
          );
        }
        return true;
      }
    ],
    description: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    footer: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    position: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    // rodo: [
    //   (v: string) => {
    //     if (
    //       (this.type() === 'TEACHER_FROM_CONTEST' ||
    //         this.type() === 'TEACHER_WITHOUT_CONTEST' ||
    //         this.type() === 'TEACHER_FROM_PROJECT') &&
    //       this.isRequiredGuard(v)
    //     ) {
    //       return CoreInputValidators.required(v);
    //     }
    //
    //     return true;
    //   }
    // ],
    requiredDocuments: [
      (v: string) => {
        if (
          (this.type() === 'TEACHER_FROM_CONTEST' ||
            this.type() === 'TEACHER_WITHOUT_CONTEST' ||
            this.type() === 'TEACHER_FROM_PROJECT') &&
          this.isRequiredGuard(v)
        ) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    tasks: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    scienceDiscipline: [
      (v: string) => {
        if (this.type() === 'TEACHER_FROM_CONTEST' && this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ], // Optional
    specialization: [], // Optional
    weRequire: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    weOffer: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ],
    eRecruiter: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }
        return true;
      },

      (v: string) => {
        if (!this.isValidHttpUrl(v)) {
          return this.$t('validation.invalid', {
            field: this.$t('views.adminPanel.validation.url'),
            subject: this.$t('views.adminPanel.validation.urlSubject')
          });
        }

        return true;
      }
    ],

    unit: [
      (v: string) => {
        if (this.isRequiredGuard(v)) {
          return CoreInputValidators.required(v);
        }

        return true;
      }
    ]
  };

  private isValidHttpUrl(input: string): boolean {
    let url;

    try {
      url = new URL(input);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  private getExpirationDate(): string | undefined {
    return this.offer.expirationDate;
  }

  private getPublicationDate(): string | undefined {
    return this.offer.publicationDate;
  }

  private getReadinessContestSettlementDate(): string | undefined {
    return this.offer.readinessContestSettlementDate;
  }

  // private autoFillEmptyDates(): void {
  //   if (!this.offer.readinessContestSettlementDate) {
  //     this.offer.readinessContestSettlementDate =
  //       this.dateScopes.readinessContestSettlementDate.min();
  //     this.onInputField('readinessContestSettlementDate');
  //   }
  //
  //   if (!this.offer.expirationDate) {
  //     this.offer.expirationDate = this.dateScopes.expirationDate.min();
  //     this.onInputField('expirationDate');
  //   }
  //
  //   if (!this.offer.termOfEmployment) {
  //     this.offer.termOfEmployment = this.dateScopes.termOfEmployment.min();
  //     this.onInputField('termOfEmployment');
  //   }
  //
  //   if (!this.offer.documentsSubmissionDeadline) {
  //     this.documentsSubmissionDeadlineDate = this.dateScopes.documentsSubmissionDeadline.min();
  //     this.documentsSubmissionDeadlineTime = '00:00';
  //     this.onInputField('documentsSubmissionDeadline');
  //   }
  // }

  private dateScopes = {
    expirationDate: {
      min: () =>
        moment(this.getReadinessContestSettlementDate() || this.getPublicationDate() || moment())
          .add(this.type() === 'TEACHER_FROM_CONTEST' ? 30 : 1, 'day')
          .format('YYYY-MM-DD')
    },
    publicationDate: {
      min: () => moment().add(1, 'day').startOf('day').format('YYYY-MM-DD'),
      max: () => {
        if (this.getExpirationDate()) {
          return moment(this.getExpirationDate()).startOf('day').format('YYYY-MM-DD');
        }

        return '';
      }
    },
    termOfEmployment: {
      min: () => {
        if (this.type() === 'TEACHER_FROM_CONTEST') {
          return moment(this.getPublicationDate() || moment())
            .add(30, 'day')
            .startOf('day')
            .format('YYYY-MM-DD');
        }

        if (this.getPublicationDate()) {
          return moment(this.getPublicationDate()).startOf('day').format('YYYY-MM-DD');
        }

        return moment().add(1, 'day').startOf('day').format('YYYY-MM-DD');
      }
      // ,
      // max: () => {
      //   if (this.getExpirationDate()) {
      //     return moment(this.getExpirationDate()).startOf('day').format('YYYY-MM-DD');
      //   }
      //
      //   return '';
      // }
    },
    readinessContestSettlementDate: {
      min: () => {
        if (this.type() === 'TEACHER_FROM_CONTEST') {
          return moment(this.getPublicationDate() || moment())
            .add(30, 'day')
            .startOf('day')
            .format('YYYY-MM-DD');
        }

        return moment().startOf('day').format('YYYY-MM-DD');
      }
    },
    documentsSubmissionDeadline: {
      min: () => {
        if (this.type() === 'TEACHER_FROM_CONTEST') {
          return moment(this.getPublicationDate() || moment())
            .add(30, 'day')
            .startOf('day')
            .format('YYYY-MM-DD');
        }

        if (this.getPublicationDate()) {
          return moment(this.getPublicationDate()).startOf('day').format('YYYY-MM-DD');
        }

        return moment().add(1, 'day').startOf('day').format('YYYY-MM-DD');
      },
      max: () => {
        if (this.getExpirationDate()) {
          return moment(this.getExpirationDate()).startOf('day').format('YYYY-MM-DD');
        }

        return '';
      }
    }
  };

  /*
    Rich text fields
   */

  // Dictates order and data
  get richTextFields(): RichTextField[] {
    return [
      {
        prop: 'description',
        errorMessages: this.errors.description,
        label: this.getLabelByPath(`offer.attributes.description`) as string,
        rules: this.rules.description,
        required: true
      },
      {
        prop: 'tasks',
        errorMessages: this.errors.tasks,
        label: this.getLabelByPath(`offer.attributes.tasks`) as string,
        rules: this.rules.tasks,
        required: true
      },
      {
        prop: 'weRequire',
        errorMessages: this.errors.weRequire,
        label: this.getLabelByPath(`offer.attributes.weRequire`) as string,
        rules: this.rules.weRequire,
        required: true
      },

      {
        prop: 'requiredDocuments',
        errorMessages: this.errors.requiredDocuments,
        label: this.getLabelByPath(`offer.attributes.requiredDocuments`) as string,
        rules: this.rules.requiredDocuments,
        required: true
      },
      {
        prop: 'weOffer',
        errorMessages: this.errors.weOffer,
        label: this.getLabelByPath(`offer.attributes.weOffer`) as string,
        rules: this.rules.weOffer,
        required: true
      },
      {
        prop: 'additionalInfo',
        errorMessages: this.errors.additionalInfo,
        label: this.getLabelByPath(`offer.attributes.additionalInfo`) as string,
        rules: this.rules.additionalInfo,
        required: true
      },
      // {
      //   prop: 'rodo',
      //   errorMessages: this.errors.rodo,
      //   label: this.getLabelByPath(`offer.attributes.rodo`) as string,
      //   rules: this.rules.rodo,
      //   required: true,
      //   default: this.defaultRodo
      // },
      {
        prop: 'footer',
        errorMessages: this.errors.footer,
        label: this.getLabelByPath(`offer.attributes.footer`) as string,
        rules: this.rules.footer,
        required: true,
        default: this.defaultFooter
      }
    ];
  }

  // Initial value - always visible text fields
  // Order does not matter
  // More data will be appended here during runtime
  private visibleRichTextFields = ['description', 'tasks', 'weRequire', 'weOffer'];

  private expandVisibleRichTextFields(): string[] {
    switch (this.getConstantByPath(`offer.type.${this.offer.type}`)) {
      case 'TEACHER_FROM_CONTEST':
      case 'TEACHER_WITHOUT_CONTEST':
      case 'TEACHER_FROM_PROJECT':
        // return this.visibleRichTextFields.concat(['additionalInfo', 'rodo', 'requiredDocuments']);
        return this.visibleRichTextFields.concat(['additionalInfo', 'requiredDocuments']);

      case 'TO_ADMINISTRATION':
        return this.visibleRichTextFields.concat(['footer']);

      default:
        return [];
    }
  }

  // Filters visible and not visible fields
  // To avoid situations where element is mounted but later hidden
  // resulting in memy leaks
  get extendedRichTextFields(): RichTextField[] {
    const fields: RichTextField[] = [];

    this.richTextFields.forEach((field: RichTextField) => {
      if (this.expandVisibleRichTextFields().includes(field.prop)) {
        fields.push(field);
      }
    });

    return fields;
  }

  private onInputField(item: string): void {
    const currentItemValue = this.offer[item as keyof Offer];
    const initialItemValue = this.initialOffer[item as keyof Offer];

    if (Object.keys(this.errors).includes(item)) {
      this.errors[item] = [];
    }

    if (currentItemValue !== initialItemValue) {
      this.editedFields.add(item);
    } else {
      this.editedFields.delete(item);
    }

    this.debounceResetAfkTimer();
  }

  private async saveAttachments(skipSuccessSnackbar: boolean = false): Promise<boolean> {
    if (this.uploadedFiles.length > 0 && this.uploadState !== 0) {
      const filesHelper = new FilesHelper(this.uploadedFiles);

      try {
        const savedOffer = await OffersService.attach(
          { uuid: this.offer.uuid },
          filesHelper.toFilesQueue()
        );

        if (savedOffer) {
          this.offer.files = savedOffer.files;
          this.uploadedFiles = [];

          if (!skipSuccessSnackbar) {
            this.offerAttachSaveSuccess();
          }

          return true;
        }

        this.offerAttachSaveError();
      } catch (e: unknown) {
        this.offerAttachSaveError();
      }

      return false;
    }

    return true;
  }

  private async saveOffer(skipSuccessSnackbar: boolean = false): Promise<boolean> {
    const filesSaved = await this.saveAttachments(skipSuccessSnackbar);

    if (filesSaved) {
      try {
        const editedOffer = this.editedOffer();

        let savedOffer: Offer | null = null;

        if (editedOffer) {
          savedOffer = await OffersService.update(editedOffer);
        }

        if (savedOffer !== null || editedOffer == null) {
          if (!skipSuccessSnackbar) {
            this.offerSaveSuccess();
          }

          return true;
        }

        this.offerSaveError();
        return false;
      } catch (e: unknown) {
        if (this.lastActionResponseError(e)) {
          this.offerSaveError();
        }

        return false;
      }
    }

    return filesSaved;
  }

  private offerSaveError() {
    this.showErrorSnackbar(
      this.$t('views.adminPanel.jobOffers.snackbar.stateValidation.message2') as string
    );
  }

  private offerSaveSuccess() {
    this.showSnackbar({
      message: this.$t('views.adminPanel.jobOffer.offerSaveOk') as string,
      type: PwrSnackbarTypes.SUCCESS
    });
  }

  private offerAttachSaveError() {
    this.showErrorSnackbar(this.$t('views.adminPanel.jobOffer.offerAttachSaveFailed') as string);
  }

  private offerAttachSaveSuccess() {
    this.showSnackbar({
      message: this.$t('views.adminPanel.jobOffer.offerAttachSaveOk') as string,
      type: PwrSnackbarTypes.SUCCESS
    });
  }

  private async onPublicationYesNoDialogYes(): Promise<void> {
    this.scheduleConfirmationLoading = true;

    const offerSaved = await this.saveOffer(true);

    if (offerSaved) {
      if (
        await this.publishOffer({
          uuid: this.offer.uuid,
          publicationDate: moment().startOf('day').format('YYYY-MM-DD')
        })
      ) {
        this.showSnackbar({
          message: this.$t(
            'views.adminPanel.jobOffers.snackbar.messages.success.published'
          ) as string,
          type: PwrSnackbarTypes.SUCCESS
        });
        this.publicationConfirmation = false;
        this.publicationConfirmationLoading = false;

        await this.routesHelper.go(AdminRoutesNames.OFFERS_ADMINISTRATION);
      } else if (this.lastActionResponseError()) {
        this.offerSaveError();
      }
    }

    this.publicationConfirmation = false;
    this.publicationConfirmationLoading = false;
  }

  private async onScheduleYesNoDialogYes(
    scheduleDate: string,
    scheduleTime: string
  ): Promise<void> {
    this.scheduleConfirmationLoading = true;

    const offerSaved = await this.saveOffer(true);

    if (offerSaved) {
      const date = `${scheduleDate} ${scheduleTime}:00`;

      if (await this.scheduleOffer({ offer: { uuid: this.offer.uuid }, date })) {
        this.showSnackbar({
          message: this.$t(
            'views.adminPanel.jobOffers.snackbar.messages.success.scheduledPublication'
          ) as string,
          type: PwrSnackbarTypes.SUCCESS
        });

        this.scheduleConfirmation = false;
        this.scheduleConfirmationLoading = false;
        await this.routesHelper.go(AdminRoutesNames.OFFERS_ADMINISTRATION);
      } else if (this.lastActionResponseError()) {
        this.offerSaveError();
      }
    }

    this.scheduleConfirmation = false;
    this.scheduleConfirmationLoading = false;
  }

  private async onArchiveYesNoDialogYes(): Promise<void> {
    this.archiveConfirmationLoading = true;

    const offerSaved = await this.saveOffer(true);

    if (offerSaved) {
      if (await this.archiveOffer({ uuid: this.offer.uuid })) {
        this.showSnackbar({
          message: this.$t(
            'views.adminPanel.jobOffers.snackbar.messages.success.archived'
          ) as string,
          type: PwrSnackbarTypes.SUCCESS
        });

        this.archiveConfirmation = false;
        this.archiveConfirmationLoading = false;
        await this.routesHelper.go(AdminRoutesNames.OFFERS_ADMINISTRATION);
      } else if (this.lastActionResponseError()) {
        this.showErrorSnackbar(
          this.$t('views.adminPanel.jobOffers.snackbar.messages.errors.canNotBeArchive') as string
        );
      }
    }

    this.archiveConfirmation = false;
    this.archiveConfirmationLoading = false;
  }

  /**
   * Offer preview
   */

  private getOfferPropByKey(key: string): string | number | undefined {
    // @ts-ignore
    return this.offer[key as keyof Offer];
  }

  get offerTextProps(): string[] {
    if (this.offer) {
      const allKeys = Object.keys(this.offer);

      let scope = [];
      if (this.offer.type === 4) {
        scope = ['description', 'tasks', 'weOffer', 'additionalInfo'];
      } else {
        // What fields to show and wit what order
        scope = ['description', 'tasks', 'weOffer', 'requiredDocuments'];
      }
      scope.filter(
        (element: string) => allKeys.includes(element) && isString(this.getOfferPropByKey(element))
      );
      return scope;
    }
    return [];
  }

  /**
   * files
   */

  private uploadedFiles: FilePondFile[] = [];
  private uploadState: UploadState = UploadState.IDLE;

  get filesProcessed(): boolean {
    return (
      (this.uploadState === UploadState.COMPLETE || this.uploadState === UploadState.IDLE) &&
      !this.uploadedFiles.find(
        (item: FilePondFile) => item.status !== FileStatus.PROCESSING_COMPLETE
      )
    );
  }

  private onFilesUploadStatusChange(state: UploadState, files: FilePondFile[]) {
    this.uploadState = state;
    this.uploadedFiles = files;
  }

  get existingFiles(): FilePondInitialFile[] {
    if (this.offer.files && this.offer.files.length) {
      const files: FilePondInitialFile[] = [];

      const filesFromDefaultGroup = this.offer.files.filter(
        (file: PwrFile) => file.source === 'default'
      );

      filesFromDefaultGroup.forEach((file: PwrFile) => {
        files.push({
          source: file.uuid,

          // set type to local to indicate an already uploaded file
          options: {
            type: 'local',

            // mock file information
            file: {
              name: file.filename,
              size: file.size
            }
          }
        });
      });

      return files;
    }

    return [];
  }

  private onFileDeleted(fileUuid: string): void {
    if (this.offer.files && this.offer.files.length) {
      this.offer.files = this.offer.files.filter((file: PwrFile) => file.uuid !== fileUuid);
    }
  }

  /**
   * Conclude competition dialog
   */

  private competitionConclusionDialog: boolean = false;

  private copyLinkToOffer() {
    this.showSnackbar({
      message: 'Link został skopiowany do schowka',
      type: PwrSnackbarTypes.SUCCESS
    });
    return this.copyToClipboard(
      (process.env as ProcessEnvBaseModel).VUE_APP_BASE_URL +
        this.$router.resolve({
          name: OfferRoutesNames.VIEW,
          params: { uuid: this.offer.uuid as string }
        }).href
    );
  }

  private copyToClipboard(text: string): boolean {
    const input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  }
}

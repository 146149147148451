import { FilePondFile } from 'filepond';
import { FilesQueue } from '@/components/Pwr/PwrFiles/lang/types';

export default class FilesHelper {
  // eslint-disable-next-line no-useless-constructor,no-empty-function
  constructor(private files: FilePondFile[], private destination: string = 'default') {}

  public toFilesQueue(): FilesQueue {
    const queue: FilesQueue = {
      destination: this.destination,
      serverIds: []
    };

    // Filter files that already exist
    this.files = this.files.filter((file: FilePondFile) => file.origin === 1);

    this.files.forEach((file: FilePondFile) => {
      queue.serverIds.push(file.serverId);
    });

    return queue;
  }
}


import { Component, Prop, PropSync, VModel, Watch } from 'vue-property-decorator';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { isString } from 'lodash';
import PwrVue from '@/components/PwrVue';
import { EditorState } from '@/components/Op/Text/EditorJs/types';
import EditorJsHelper from '@/components/Op/Text/EditorJs/EditorJsHelper';
import PwrCardTitle from '@/components/Pwr/PwrCard/PwrCardTitle.vue';
import PwrCard from '@/components/Pwr/PwrCard/PwrCard.vue';
import PwrBtn from '@/components/Pwr/Buttons/PwrBtn/PwrBtn.vue';
import './EditorJs/EditorJsEditorStyles.scss';

@Component({
  components: { PwrCardTitle, PwrCard, PwrBtn }
})
export default class OpTextEditor extends PwrVue {
  @Prop() label!: string | null;
  @Prop() disabled!: boolean | string;
  @Prop() input!: boolean | null;
  @Prop() small!: string | boolean;
  @Prop({ required: true }) id!: string;

  @Prop({ default: () => [] }) rules!: { (input: any): boolean | string }[];
  @PropSync('errors') errorMessages!: string[] | undefined;

  @VModel() content!: string | undefined;
  @Prop() defaultContent!: string | undefined;

  private editor: EditorJS | null = null;
  private editorState = EditorState.INIT;
  private internalUpdate = false;

  private internalErrorMessages: string[] = [];

  private async mounted(): Promise<void> {
    if (!this.editor) {
      if (!this.content){
        this.content ="{\"time\":1659608613765,\"blocks\":[{\"id\":\"AtraMDyAJ-\",\"type\":\"paragraph\",\"data\":{\"text\":\"\"}}],\"version\":\"2.24.3\"}";
      }

      await this.initEditor();
    }
  }

  public async initEditor(): Promise<void> {
    this.editor = new EditorJsHelper().getNewEditorJsInstance(
      this.editorId(),
      this.parsedContent(),
      false,
      () => this.updateContent(),
      this.$t('components.op.textEditor.placeholder') as string
    );

    try {
      await this.editor.isReady;
      this.editorState = EditorState.READY;
      this.onContentChange();
    } catch (reason) {
      this.editorState = EditorState.ERROR;
    }
  }

  private clearErrors(): void {
    this.errorMessages = [];
    this.internalErrorMessages = [];
  }

  private async updateContent(): Promise<void> {
    if (this.editor) {
      this.internalUpdate = true;
      this.clearErrors();

      const data = await this.editor.save();

      this.content = data.blocks.length > 0 ? JSON.stringify(data) : '';

      this.checkRules(data);
    }
  }

  private parsedContent(): OutputData | undefined {
    if (this.content) {
      try {
        const data: OutputData = JSON.parse(this.content);

        if (data.blocks.length === 0) {
          return undefined;
        }

        return data;
      } catch (error: unknown) {
        return {
          blocks: [
            {
              type: 'paragraph',
              data: { text: this.content }
            }
          ]
        };
      }
    }

    return undefined;
  }

  // Looks for content change to reinitialize editor if the update was from outside.
  @Watch('content')
  private onContentChange(): void {
    if (this.editor && this.content) {
      this.clearErrors();
    }


    if (
      this.content &&
      !this.internalUpdate &&
      this.editorState === EditorState.READY &&
      this.editor
    ) {
      this.clearErrors();

      const data = this.parsedContent();

      if (data) {
        this.editor?.render(data);
      }
    }

    this.internalUpdate = false;
  }

  private checkRules(content: OutputData | undefined): void {
    if (this.rules) {
      this.rules.forEach((rule: { (input: any): boolean | string }) => {
        // content will always have something inside (object)
        // So I check if it has data blocks, if it does then I output random sting, else empty
        // string to mimic presence or absence of data

        let result: string | boolean = false;

        if (content) {
          result = rule(content.blocks.length > 0 ? 'random test string' : '');
        }

        if (isString(result)) {
          this.internalErrorMessages.push(result);
        } else if (!result) {
          this.internalErrorMessages.push('Default error message');
        }
      });
    }
  }

  private editorId(): string {
    return `editor-${this.id}`;
  }

  private errorsMess() {
    return this.internalErrorMessages.concat(this.errorMessages ?? []);
  }

  private onDefaultButtonClick(): void {
    this.content = this.defaultContent;
  }
}
